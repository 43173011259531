import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Header} from "../Components/Header";
import {Navigation} from "../Components/Navigation";
export class History extends React.Component {
    open(id){
        this.props.app.HisDetails(id);
    }
    render() {
        let list = this.props.app.state.his.map((item, index)=>{
            let key = 'hist-list-' + index;
            return <li key={key} onClick={this.open.bind(this, item.id)}>{item.name} <span>{item.date}</span></li>
        });
        return (<div className='app-container'>
            <Header app={this.props.app} title="История Закупок"/>
            <ul className="hist-list">
                {list}
            </ul>
            <Navigation app={this.props.app}/>
        </div>);
    }
}