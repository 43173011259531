import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Header} from "../Components/Header";
import {Navigation} from "../Components/Navigation";
import {NavigationSelect} from "../Components/NavigationSelect";

export class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewCart: false,
            close: true,
            name: '',
        };
    }
    open(id){ console.log('open clicked', id); }
    addItem(){
        this.props.app.goScreen('additem');
    }
    add(){
        this.setState({showNewCart: true});
    }
    sw(){
        this.setState({close: !this.state.close});
    }
    ok(){
        this.props.app.addCart(this.state.name, this.state.close);
    }
    nameChange(e){
        this.setState({name: e.target.value});
    }

    render() {
        let SoTotal = 0;
        console.log('cart is ', this.props.app.state.cart, this.props.app.state.cartInfo);
        let list = this.props.app.state.cart[this.props.app.state.cartInfo.pos].items.map((item, index) => {
            let total;
            let cost;
            if(parseInt(item.total)){
                total = this.props.app.formatMoney(item.total, 2, ',', ' ');
                SoTotal += item.total;
                total = total.toString() + ' ₽';
            } else {
                total = '';
            }
            cost = this.props.app.formatMoney(item.cost, 2, ',', ' ');
            let key = 'cart-list-' + index;
            let sub = [];
            console.log('this.count', item.count);
            if(item.count > 0){
                sub = [item.count, ' ', item.unit.short]
            }
            if(item.cost > 0){
                if(sub.length > 0){
                    let key = 'b-sub-' + sub.length + '-' + Math.random()*100000;
                    sub.push(<b key={key}>x</b>);
                }
                sub.push(' ', cost.toString(), ' ₽');
            }

            return <li key={key} onClick={this.open.bind(this, item.id)}>
                <div className="title">{item.name}</div>
                <div className="sub-title">{sub}</div>
                <div className="total">{total}</div>

            </li>;
        });
        let st = this.props.app.formatMoney(SoTotal, 2, ',', ' ');
        let cartList = this.props.app.state.cart.map((item, index)=>{
            return {
                id:item.id,
                name: item.name,
                pos: index,
            };
        });
        /*<Header app={this.props.app} title={this.props.app.state.cart[this.props.app.state.cartInfo.pos].name}  button={<div className='dicBtn dicAdd' onClick={this.add.bind(this)}></div>} />*/
        return (<div className='app-container'>
            {this.state.showNewCart && <div className="modal">
                <div className="modal-frame">
                    <div>Название нового списка</div>
                    <div className="">
                        <input type="text" placeholder={"Название"} value={this.state.name} onChange={this.nameChange.bind(this)} autoFocus={true} />
                    </div>
                    <div><div onClick={this.sw.bind(this)} className={['sw', this.state.close && 'active'].join(' ')}></div><span>Завершить предыдущий список</span></div>
                    <div className="round-btn" onClick={this.ok.bind(this)}>Ок</div>
                </div>
            </div>}
            <Header app={this.props.app} title={<NavigationSelect list={cartList} title={this.props.app.state.cart[this.props.app.state.cartInfo.pos].name} app={this.props.app} />}  button={<div className='dicBtn dicAdd' onClick={this.add.bind(this)}></div>} />
            <ul className="cart-list content">
                {list}
            </ul>
            <div className="addItem" onClick={this.addItem.bind(this)}></div>
            <div className="soTotal">
                <div className="title">
                    Итого:
                </div><div className="money">
                    {st} <b>₽</b>
                </div>
            </div>
            <Navigation app={this.props.app}/>
        </div>);
    }
}