import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Header} from '../Components/Header'
import {Navigation} from '../Components/Navigation'
export class DicDetails extends React.Component {
    goBack(){
        this.props.app.goScreen('dic', {dic_id: null} );
    }
    render() {
        let info = this.props.app.state.dicElement;
        let list = this.props.app.state.dicHist.map((item, index)=>{
            let key = 'dic-hist-list-' + index;
            return <li key={key}>
                <div>{item.count} {item.unit} по {this.props.app.formatMoney(item.cost, 2, ',', ' ')} ₽</div>
                <div>{item.date}</div>
                <div>{this.props.app.formatMoney(item.cost * item.count, 2, ',', ' ')} ₽</div>
            </li>;
        });
        return (<div className='app-container'>
            <Header app={this.props.app} title="Справочник"/>
            <div className="dic-detail-container content">
                <div className="grad">
                    <div className="ico"></div>
                    {info.name}
                    <div className="cross" onClick={this.goBack.bind(this)}></div>
                </div>
                <ul className='dic-info'>
                    {info.min && <li>Минимальная цена: <span className='round min'>{this.props.app.formatMoney(info.min, 0, ',', ' ')} <b>₽</b></span></li>}
                    {info.avg && <li>Средняя цена: <span className='round avg'>{this.props.app.formatMoney(info.avg, 0, ',', ' ')} <b>₽</b></span></li>}
                    {info.max && <li>Максимальная цена: <span className='round max'>{this.props.app.formatMoney(info.max, 0, ',', ' ')} <b>₽</b></span></li>}
                    {info.last && <li>Цена последней покупки: <span className='round last'>{this.props.app.formatMoney(info.last, 0, ',', ' ')} <b>₽</b></span></li>}
                    <li className='buycount'>Кол-во покупок: <span>{info.count}</span></li>
                    {info.size && <li>Объем покупок: <span>{info.size} {info.units.short}</span></li>}
                    <li>Общая стоимость покупок: <span>{this.props.app.formatMoney(info.total, 0, ',', ' ')} <b>₽</b></span></li>
                </ul>
                {list.length > 0 && [
                    <h2 key='e1'>История покупок ({list.length}):</h2>,
                    <ul key='e2' className='dic-history'>
                        {list}
                    </ul>
                ]}
            </div>
            <Navigation app={this.props.app}/>
        </div>);
    }
}