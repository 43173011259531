import React from 'react';
import 'font-awesome/css/font-awesome.min.css';

export class AddItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cost_unit: 0,
            cost_all: 0,
            count: 0,
            unit: null,
            item: null,
            ch: null,
            input: '',
        };
    }

    moneyInput(field, e){
        let val;
        if(e.target.value === ''){
            val = e.target.value;
        } else {
            val = parseFloat(e.target.value).toFixed(2);
        }
        let change = {};
        change[field] = val;
        change.ch = field;
        if(this.state.count > 0){
            switch (field) {
                case 'cost_unit':
                    change.cost_all = (val * this.state.count).toFixed(2);
                    break;
                case 'cost_all':
                    change.cost_unit = (val / this.state.count).toFixed(2);
                    break;

            }
        }
        this.setState(change);
    }
    changeItem(e){
        let val = e.target.value.toLowerCase();
        this.setState({input: val})

    }
    selectItem(item){
        let unit = this.props.app.findUnit(item.unit);
        console.log('select', item, unit);
        this.setState({item: item, unit: unit});
    }
    weightHelp(val){
        let change = {};
        change.count = val;
        switch (this.state.ch) {
            case 'cost_unit':
                change.cost_all = (this.state.cost_unit * val).toFixed(2);
                break;
            case 'cost_all':
                change.cost_unit = (this.state.cost_all / val).toFixed(2);
                break;

        }
        this.setState(change);
    }
    weightChange(e){
        let change = {};
        let val = e.target.value;
        change.count = val;
        switch (this.state.ch) {
            case 'cost_unit':
                change.cost_all = (this.state.cost_unit * val).toFixed(2);
                break;
            case 'cost_all':
                change.cost_unit = (this.state.cost_all / val).toFixed(2);
                break;

        }
        this.setState(change);
    }
    save(){
        if(this.state.item && (this.state.cost_unit > 0 || this.state.cost_all > 0 || this.state.count > 0)){
            this.props.app.ItemAdd(this.state);
        } else {
            console.error('save not ready', this.state);
        }
    }
    goBack(){
        this.props.app.goScreen('main');
    }
    render() {
        let showForm = this.state.item ? true : false;
        let list = this.props.app.state.dic.filter((item) => {
            if(this.state.input.length < 1){
                return false;
            }
            return item.name.toLowerCase().includes(this.state.input);
        }).map((item, index)=>{
            let key = 'help-' + index;
            return <li key={key} onClick={this.selectItem.bind(this, item)}>{item.name}</li>;
        });
        return (<div className='app-container add-item'>
            <div className="add-item-header">
                <div className="btn-back" onClick={this.goBack.bind(this)}></div>
                <div className="title">Добавить товар</div>
            </div>

            {showForm ?
            [
                <div key="u1" className="add-item-container pad-top">
                    <div>{this.state.item.name}</div>
                </div>,
                <div key="u2" className="input-title">За единицу</div>,
            <input key="u3" type="number" className="input-field" value={this.state.cost_unit} onChange={this.moneyInput.bind(this, 'cost_unit')} placeholder={"100р"}/>,
            <div key="u4" className="input-title">За все сразу</div>,
            <input key="u5" type="number" className="input-field" value={this.state.cost_all} onChange={this.moneyInput.bind(this, 'cost_all')}  placeholder={"100р"}/>,
            <div key="u6" className="input-title">Кол-во</div>,
            <input key="u7" type="number" className="input-field-short" value={this.state.count} onChange={this.weightChange.bind(this)} placeholder={"10"}/>,
            <div key="u8" className="input-units">
                <div className="current">{this.state.unit.short}</div>
                <ul>
                    <li>Кг.</li>
                    <li>Л.</li>
                </ul>
            </div>,
            <ul key="u9"className='item-cost-statistic'>
                <li onClick={this.weightHelp.bind(this, 1)}>1 {this.state.unit.short}</li>
                <li onClick={this.weightHelp.bind(this, 2)}>2 {this.state.unit.short}</li>
                <li onClick={this.weightHelp.bind(this, 3)}>3 {this.state.unit.short}</li>
                <li onClick={this.weightHelp.bind(this, 5)}>5 {this.state.unit.short}</li>
            </ul>,
            <div key="u10" className="statistic">
                <div className="title">Стоимость</div>
                <ul>
                    <li className='min'  >{this.props.app.formatMoney(this.state.item.min, 0, ',' , ' ')} ₽</li>
                    <li className='avg'  >{this.props.app.formatMoney(this.state.item.avg, 0, ',' , ' ')} ₽</li>
                    <li className='max'  >{this.props.app.formatMoney(this.state.item.max, 0, ',' , ' ')} ₽</li>
                    <li className='last' >{this.props.app.formatMoney(this.state.item.last, 0, ',' , ' ')} ₽</li>
                </ul>
            </div>,
            <div key="u11" className="btn-ok" onClick={this.save.bind(this)}></div>
            ] : [<div key="u12" className="add-item-container pad-top">
                    <input type="text" placeholder={"Товар"} onChange={this.changeItem.bind(this)} autoFocus={true}/>
                </div>, list.length > 0 && <ul key="u13" className="dic-help">{list}</ul>]
            }
        </div>);
    }
}