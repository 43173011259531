import React, { Component } from 'react';
import './Styles/App.css';
import {Loading} from './screens/Loading'
import {Loader} from './screens/Loader'
import {Main} from './screens/Main'
import {AddItem} from './screens/AddItem'
import {Dic} from './screens/Dic'
import {DicAdd} from './screens/DicAdd'
import {DicDetails} from './screens/DicDetails'
import {History} from './screens/History'
import {HistoryDetails} from './screens/HistoryDetails'
import {Settings} from './screens/Settings'
import {Login} from './screens/Login'
import {Registration} from './screens/Registration'

class App extends Component {
    constructor(props) {
        super(props);
        console.log('userID', document.location.hash.replace('#', ''));
        this.state = {
            server_version: null,
            server_build: null,
            screen : 'loader',
            default_screen : 'dic',
            loading: false,
            prvScreen : null,
            nextScreen: null,
            error_msg: null,
            hash: null,
            nav: 'dic',
            //host : 'http://mc2.local',
            host : 'https://v2.mcalc.ru',
            dicElement: {},
            hisElement: [],
            cart: [],
            dic:[[]],
            units: [],
            sections:{},
            cartInfo: {},
            dicHist: [],
            dic_id: null, // dic id to show in dic details
            header_opened_menu: false,
        };
    }
    goScreen(screen, params = {}){
        let change = {};
        let navSet = false;
        for(let i in params){
            if(i === 'nav'){
                navSet = true;
            }
            change[i] = params[i];
        }
        change.screen = screen;
        if(!navSet){
            switch (screen) {
                case 'dicdetails':
                case 'dic':
                    change.nav = 'dic';
                    break;
                case 'historydetails':
                case 'history':
                    change.nav = 'history';
                    break;
                default:
                    change.nav = 'main';
            }
        }
        this.setState(change);
    }

    net_error(tag, data){
        console.error('net error ['+ tag + ']', data);
    }
    getDataFromServer(tag, uri, post_data, success, fail, need_loader_start = false, need_loader_end = false){
        if(need_loader_start){
            this.setState({loading: true});
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', this.state.host + uri, true);

        var data = new FormData();

        for(let i in post_data){
            data.append(i, post_data[i]);
        }


        //xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send(data);

        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }

            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let response = JSON.parse(xhr.responseText);
                console.log('data from server('+tag+')', response);
                if(response.status){
                    success(response);
                } else {
                    if(fail){
                        fail(response);
                    }
                    this.net_error(tag, response);
                }
            }
        }
    }

    getStartData(hash, screen = null){
        this.getDataFromServer(
            'start',
            '/api/ru/v2/load.json',
            {token: hash},
            (data)=>{
                const pos = 0;
                let change = {};
                change.dic = data.data.dictionary;
                change.his = data.data.history;
                change.user = data.data.user;
                change.units = data.data.units;
                change.sections = {};
                change.cart = data.data.zak;
                change.cartInfo = {
                    pos: pos,
                    id: change.cart[pos].id,
                };
                change.sections.kitchen = data.data.sections[1];
                change.sections.bar = data.data.sections[2];
                change.sections.other = data.data.sections[3];
                change.hash = hash;
                change.loading = false;
                this.goScreen(screen || this.state.default_screen, change);
            },
            (data)=>{
                if(data.code === 1 || data.code === 2){
                    localStorage.removeItem('auth_hash');
                    this.goScreen('login');
                }
              console.error('start data error', data);
            },
            false,
            false,

        );
        console.log('hash!', hash);
    }
    DicDetails(id){
        console.log('details ID', id);
        this.getDataFromServer(
            'DicDetails',
            '/api/ru/v2/dic/details.json',
            {
                token: this.state.hash,
                id: id,
            },
            (data)=>{
                this.goScreen('dicdetails',{dicElement: data.dic, dicHist: data.list, loading: false});
            },
            (data)=>{
                console.error('start data error', data);
            },
            true,
            true,

        );
    }
    HisDetails(id){
        console.log('details ID', id);
        this.getDataFromServer(
            'HisDetails',
            '/api/ru/v2/purchase/load.json',
            {
                token: this.state.hash,
                id: id,
            },
            (data)=>{
                console.log('data', data);
                this.goScreen('historydetails',{hisElement: data.data, loading: false});
            },
            (data)=>{
                console.error('start data error', data);
            },
            true,
            true,

        );
    }
    ItemAdd(data){
        this.getDataFromServer(
            'ItemAdd',
            '/api/ru/v2/purchase/item/add.json',
            {
                token: this.state.hash,
                did: data.item.id,
                count: data.count,
                cost: data.cost_unit,
                unit: data.unit.id,
                cart: this.state.cartInfo.id,
            },
            (data)=>{
                console.log('ItemAdd response',data);
                let cart = this.state.cart;
                cart[this.state.cartInfo.pos].items.push(data.item);
                this.goScreen('main',{cart:cart, loading: false});
            },
            (data)=>{
                console.error('start data error', data);
            },
            true,
            true,

        );
    }
    addCart(name, close){
        this.getDataFromServer(
            'addCart',
            '/api/ru/v2/purchase/add.json',
            {
                token: this.state.hash,
                name: name,
                close: close,
                id: this.state.cartInfo.id,
            },
            (data)=>{
                console.log('ItemAdd response',data);
                let cart = this.state.cart;
                cart.unshift({
                    id:	data.id,
                    name: name,
                    items: []
                });
                if(close){
                    for(let i in cart){
                        if(cart[i].id === this.state.cartInfo.id){
                            cart.splice(i, 1);
                        }
                    }
                }
                let info = this.state.cartInfo;
                info.id = data.id;
                info.pos = 0;
                console.log('try to save', cart, info, cart.length);
                if(close){
                    this.getStartData(this.state.hash, 'main');
                } else {
                    this.goScreen('main', {loading: false, cart: cart, cartInfo: info});
                }
                //this.goScreen('dicdetails',{dicElement: data.dic, loading: false});
            },
            (data)=>{
                console.error('start data error', data);
            },
            true,
            true,

        );
    }
    formatMoney(n, c, d, t) {
        var c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d == undefined ? "." : d,
            t = t == undefined ? " " : t,
            s = n < 0 ? "-" : "",
            i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
            j = (j = i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    findUnit(id){
        let result = this.state.units.filter((item) => {
            return item.id === id;
        });
        if(result.length > 0){
            return result[0];
        }
        return null;
    }
    render() {
        if(this.state.loading === true){
            return (<Loading />);
        }
        console.log('load', this.state.screen);
        switch (this.state.screen) {
            case 'loader': return (<Loader app={this} />);
            case 'main': return (<Main app={this} />);
            case 'additem': return (<AddItem app={this} />);
            case 'dic': return (<Dic app={this} />);
            case 'dicadd': return (<DicAdd app={this} />);
            case 'dicdetails': return (<DicDetails app={this} />);
            case 'history': return (<History app={this} />);
            case 'historydetails': return (<HistoryDetails app={this} />);
            case 'settings': return (<Settings app={this} />);
            case 'login': return (<Login app={this} />);
            case 'registration': return (<Registration app={this} />);
            default:
                return (
                    <div className="App">
                        Found screen <b>{this.state.screen}</b>
                    </div>
                );
        }
    }
}

export default App;
