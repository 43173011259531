import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Header} from "../Components/Header";
import {Navigation} from "../Components/Navigation";
export class HistoryDetails extends React.Component {
    goBack(){
        this.props.app.goScreen('history', {dic_id: null} );
    }
    open(){}
    render() {
        let SoTotal = 0;
        let info = this.props.app.state.hisElement;
        let list = info.items.map((item, index)=>{
            let total_i = parseFloat(item.cost).toFixed(2) * parseFloat(item.count).toFixed(3);
            console.log('test', parseFloat(item.cost).toFixed(2), parseFloat(item.count).toFixed(3), total_i);
            let cost;
            let total = this.props.app.formatMoney(total_i, 2, ',', ' ') + ' ₽';
            SoTotal += total_i;

            let key = 'hist-details-list-' + index;
            cost = this.props.app.formatMoney(item.cost, 2, ',', ' ');
            let sub = [];
            console.log('this.count', item.count);
            if(item.count > 0){
                sub = [item.count, ' ', item.unit.short]
            }
            if(item.cost > 0){
                if(sub.length > 0){
                    let key = 'b-sub-' + sub.length + '-' + Math.random()*100000;
                    sub.push(<b key={key}>x</b>);
                }
                sub.push(' ', cost.toString(), ' ₽');
            }
            return <li key={key} onClick={this.open.bind(this, item.id)}>
                <div className="title">{item.name}</div>
                <div className="sub-title">{sub}</div>
                <div className="total">{total}</div>

            </li>
        });
        console.log('info', info);
        return (<div className='app-container'>
            <Header app={this.props.app} title="Справочник"/>
            <div className="dic-detail-container content">
                <div className="grad">
                    {info.name}
                    <div className="cross" onClick={this.goBack.bind(this)}></div>
                </div>
                <ul className='dic-info'>
                    <li className='buycount'>Создание списка: <span>{info.dateStart}</span></li>
                    <li>Завершение списка: <span>{info.dateEnd}</span></li>
                    <li>Кол-во позиций: <span>{list.length}</span></li>
                    <li>Общая стоимость покупок: <span>{this.props.app.formatMoney(SoTotal, 2, ',', ' ')} <b>₽</b></span></li>
                </ul>
                {list.length > 0 && [
                    <h2 key='e1'>Список:</h2>,
                    <ul key='e2' className='cart-list content history-cart-list'>
                        {list}
                    </ul>
                ]}
            </div>
            <Navigation app={this.props.app}/>
        </div>);
    }
}