import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Header} from '../Components/Header'
import {Navigation} from '../Components/Navigation'
export class Dic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            s1: null,
            s2: null,
        };
    }
    open(id){
        let tmr = setInterval(()=>{
            console.log('dic', {dic_id: id});
            this.props.app.DicDetails(id);
            clearInterval(tmr);
        }, 130);
    }
    add(){
        this.props.app.goScreen('dicadd');
    }
    showS1section(id){
        if(id === this.state.s1){
            this.setState({s1: null});
        } else {
            this.setState({s1: id});
        }
    }
    showS2section(id, name){
        console.log('s2', id);
        if(id === this.state.s2){
            this.setState({s2: null, s2name: null});
        } else {
            this.setState({s2: id, s2name: name});
        }
    }
    render() {
        let list = [];
        if (this.state.s2 === null) {
            list = this.props.app.state.dic.map((item, index) => {
                let key = 'dic-list-' + index;
                return <li key={key} onClick={this.open.bind(this, item.id)}>
                    <div className="title">{item.name}</div>
                    <ul className="hist">
                        <li className="min">{item.min}</li>
                        <li className="avg">{item.avg}</li>
                        <li className="max">{item.max}</li>
                        <li className="last">{item.last}</li>
                    </ul>
                </li>;
            });
        } else {
            list = this.props.app.state.dic.filter((item) => {
                return item.section === this.state.s2;
            }).map((item, index) => {
                let key = 'dic-list-' + index;
                return <li key={key} onClick={this.open.bind(this, item.id)}>
                    <div className="title">{item.name}</div>
                    <ul className="hist">
                        <li className="min">{item.min}</li>
                        <li className="avg">{item.avg}</li>
                        <li className="max">{item.max}</li>
                        <li className="last">{item.last}</li>
                    </ul>
                </li>;
            });
        }
        let list2 = null;

        let list2key = 'kitchen';
        if(this.state.s1 === 2){
            list2key = 'bar';
        }
        if(this.state.s1 === 3){
            list2key = 'other';
        }
        if(this.state.s1 !== null){
            list2 = this.props.app.state.sections[list2key].map((item, index) => {
                let key = 'list-2-' + index;
                return <li key={key} className={this.state.s2 === item.id ? 'active' : null} onClick={this.showS2section.bind(this, item.id, item.name)}>{item.name}</li>;
            });
        }
        console.log('list2', list2, this.state.s1, list2key);
        return (<div className='app-container'>
            <Header app={this.props.app} title="Справочник"  button={<div className='dicBtn dicAdd' onClick={this.add.bind(this)}></div>}/>
            <ul className="dic-s1-list">
                <li onClick={this.showS1section.bind(this, 1)} className={this.state.s1 === 1 ? 'active' : null}>Кухня</li>
                <li onClick={this.showS1section.bind(this, 2)} className={this.state.s1 === 2 ? 'active' : null}>Бар</li>
                <li onClick={this.showS1section.bind(this, 3)} className={this.state.s1 === 3 ? 'active' : null}>Хоз. расходы</li>
            </ul>
            {list2 && <ul className='dic-sec-list'>{list2}</ul>}
            {this.state.s2 && <div className="grad">
                <div className="ico"></div>
                {this.state.s2name}
                <div className="cross" onClick={this.showS2section.bind(this, null, null)}></div>
            </div>}
            <ul className="dic-list content">
                {list}
            </ul>
            <Navigation app={this.props.app}/>
        </div>);
    }
}