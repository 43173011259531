import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
export class DicAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unit: {},
            name: '',
            article: '',
            section : {},
            error_msg: null,
        };
    }
    goBack(){
        this.props.app.goScreen('dic');
    }
    nameChange(e){
        this.setState({name: e.target.value});
    }
    articleChange(e){
        this.setState({article: e.target.value});
    }
    sectionChange(e){
        if(e.target){
            let item = null;
            for(let i in this.props.app.state.sections){
                for(let j in this.props.app.state.sections[i]){
                    if(e.target.value === this.props.app.state.sections[i][j].id){
                        item = this.props.app.state.sections[i][j];
                    }
                }
            }
            console.log('sectionChange', item);
            this.setState({section: item});
        }
    }
    unitChange(e){
        if(e.target){
            let item = null;
            for(let i in this.props.app.state.units){
                if(e.target.value === this.props.app.state.units[i].id){
                    item = this.props.app.state.units[i];
                }
            }
            console.log('unitChange', item);
            this.setState({unit: item});
        }
    }
    save(){
        if(this.state.name.length < 3){
            this.props.app.setState({error_msg: 'имя должно быть минимум 3 символов'});
            return false;
        }
        if(!this.state.section.id){
            this.props.app.setState({error_msg: 'Выберите раздел'});
            return false;
        }
        if(!this.state.unit.id){
            this.props.app.setState({error_msg: 'Выберите единицу измерения'});
            return false;
        }
        let __this = this;
        let __state = this.state;
        this.props.app.getDataFromServer(
            'dicAdd',
            '/api/ru/v2/dic/add.json',
            {
                token: this.props.app.state.hash,
                name: this.state.name,
                article: this.state.article,
                unit: this.state.unit ? this.state.unit.id: null,
                section: this.state.section ? this.state.section.id: null,
            },
            (data)=>{
                console.log('dicAdd callback', data.data);
                this.props.app.goScreen('dic', {dic: data.dic, loading: false, error_msg: ''});
            },
            (data)=>{
                if(data.code === 2){
                    this.props.app.setState({loading: false, error_msg: 'Такой товар уже существует'});
                }
                let tmr = setInterval(()=>{
                    __this.setState(__state);
                    clearInterval(tmr);
                }, 100);
                console.error('dic add data error', data);
            },
            true,
            false,
        );
    }
    render() {
        let unitList = this.props.app.state.units.map((item, index)=>{
            let key = 'unit-list-' + index;
            return <option key={key} value={item.id}>{item.name}</option>
        });
        let sectionList1 = this.props.app.state.sections.kitchen.map((item, index)=>{
            let key = 'section1-list-' + index;
            return <option value={item.id} key={key} onClick={this.sectionChange(this, item)}>{item.name}</option>
        });
        let sectionList2 = this.props.app.state.sections.bar.map((item, index)=>{
            let key = 'section2-list-' + index;
            return <option value={item.id} key={key} onClick={this.sectionChange(this, item)}>{item.name}</option>
        });
        let sectionList3 = this.props.app.state.sections.other.map((item, index)=>{
            let key = 'section3-list-' + index;
            return <option value={item.id} key={key} onClick={this.sectionChange(this, item)}>{item.name}</option>
        });
        return (<div className='app-container add-item'>
            {this.props.app.state.error_msg && <div className='login-error pad-top'>{this.props.app.state.error_msg}</div>}
            <div className="add-item-header">
                <div className="btn-back" onClick={this.goBack.bind(this)}></div>
                <div className="title">Добавить в справочник</div>
            </div>
            <div className="add-item-container pad-top">
                <input type="text" placeholder={"Название"} value={this.state.name} onChange={this.nameChange.bind(this)} autoFocus={true}/>
            </div>
            <div className="input-title">Артикул</div>
            <div className="add-item-container">
                <input type="text" placeholder={"Артикул"} value={this.state.article} onChange={this.articleChange.bind(this)}/>
            </div>
            <div className="input-title">Раздел</div>
            <select className="input-field" onChange={this.sectionChange.bind(this)}>
                <option> </option>
                <optgroup label="Кухня">
                    {sectionList1}
                </optgroup>
                <optgroup label="Бар">
                    {sectionList2}
                </optgroup>
                <optgroup label="Хоз расходы">
                    {sectionList3}
                </optgroup>
            </select>
            <div className="input-title">Ед измерения</div>
            <select className="input-field" onChange={this.unitChange.bind(this)}>
                <option> </option>
                {unitList}
            </select>
            <div className="btn-ok" onClick={this.save.bind(this)}></div>
        </div>);
    }
}