import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
export class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            email: '',
            key:   '',
            pwd1:  '',
            pwd2:  '',
            name1:  '',
            name2:  '',
            error_msg: null,
        };
    }
    reg(){
        let __this = this;
        this.props.app.getDataFromServer(
            'login',
            '/api/ru/v2/registration2.json',
            {
                phone: this.state.phone,
                email: this.state.email,
                key: this.state.key,
                pwd1: this.state.pwd1,
                pwd2: this.state.pwd2,
                name1: this.state.name1,
                name2: this.state.name2,
            },
            (data)=>{
                __this.props.app.goScreen('login', {loading: false,})
            },
            (data)=>{
                console.error('auth data error', data);
                this.props.app.setState({loading: false, error_msg: data.msg_ru});
            },
            true,
            false,
        );
        console.log('auth', this.state.login, this.state.pwd);
    }
    dataChange(token, e){
        let change = {};
        change[token] = e.target.value;
        this.setState(change);
    }
    render() {
        return (<div className='Login reg'>
            {this.props.app.state.error_msg && <div className='login-error'>{this.props.app.state.error_msg}</div>}
            <span>Регистрация</span>
            <input type="email" placeholder={"E-mail"} value={this.state.email} onChange={this.dataChange.bind(this, 'email')} autoFocus={true}/>
            <input type="tel" placeholder={"Телефон"} value={this.state.phone} onChange={this.dataChange.bind(this, 'phone')}/>
            <input type="text" placeholder={"Ключ"} value={this.state.key} onChange={this.dataChange.bind(this, 'key')}/>
            <input type="text" placeholder={"Имя"} value={this.state.name1} onChange={this.dataChange.bind(this, 'name1')}/>
            <input type="text" placeholder={"Фамилия"} value={this.state.name2} onChange={this.dataChange.bind(this, 'name2')}/>
            <input type="password" placeholder={"Пароль"} value={this.state.pwd1} onChange={this.dataChange.bind(this, 'pwd1')}/>
            <input type="password" placeholder={"Повтор Пароля"} value={this.state.pwd2} onChange={this.dataChange.bind(this, 'pwd2')}/>
            <div className="btn-ok" onClick={this.reg.bind(this)}></div>
        </div>);
    }
}