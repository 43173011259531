import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
export class Header extends React.Component {
    render() {
        return (<div className='header'>
            <div className="logo"></div>
            <div className='title'>{this.props.title}</div>
            {this.props.button ? this.props.button : null}
        </div>);
    }
}