import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
export class Login extends React.Component {
    constructor(props) {
        super(props);
        console.log('userID', document.location.hash.replace('#', ''));
        this.state = {
            login: '',
            pwd: '',
        };
    }
    login(){
        let __this = this;
        this.props.app.getDataFromServer(
            'login',
            '/api/ru/v2/auth.json',
            {
                login: this.state.login,
                password: this.state.pwd,
                platform: 'ios',
                deviceID: ''
            },
            (data)=>{
                console.log('login callback', data.data);
                localStorage.setItem('auth_hash', data.data.token);
                __this.props.app.goScreen('loader', {loading: false, hash: data.data.token,})
            },
            (data)=>{
                console.error('auth data error', data);
                this.props.app.setState({loading: false, error_msg: data.msg_ru});
            },
            true,
            false,
        );
        console.log('auth', this.state.login, this.state.pwd);
    }
    loginChange(e){
        this.setState({login: e.target.value});
    }
    passwordChange(e){
        this.setState({pwd: e.target.value});
    }
    reg(){
        this.props.app.goScreen('registration');
    }
    render() {
        return (<div className='Login'>
            {this.props.app.state.error_msg && <div className='login-error'>{this.props.app.state.error_msg}</div>}
            <span>Авторизация</span>
            <input type="text" placeholder={"Логин"} value={this.state.login} onChange={this.loginChange.bind(this)} autoFocus={true}/>
            <input type="password" placeholder={"Пароль"} value={this.state.pwd} onChange={this.passwordChange.bind(this)}/>
            <div className="btn-ok" onClick={this.login.bind(this)}></div>
            <ul className="login-links">
                <li onClick={this.reg.bind(this)}>Регистрация</li>
                {/*<li>О программе</li>
                <li>Политика конфиденциальности</li>*/}
            </ul>
        </div>);
    }
}