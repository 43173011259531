import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Header} from "../Components/Header";
import {Navigation} from "../Components/Navigation";
export class Settings extends React.Component {
    render() {
        return (<div className='app-container'>
            <Header app={this.props.app}/>
            <span>Settings</span>
            <Navigation app={this.props.app}/>
        </div>);
    }
}