import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import '../Styles/navigation.css';
export class Navigation extends React.Component {
    nav(screen){
        this.props.app.goScreen(screen, {nav: screen});
    }
    render() {
        return (<ul className='navigation'>
            <li onClick={this.nav.bind(this, 'dic')} className={['dic', this.props.app.state.nav === 'dic' && 'active'].join(' ')}></li>
            <li onClick={this.nav.bind(this, 'main')} className={['main', this.props.app.state.nav === 'main' && 'active'].join(' ')}></li>
            <li onClick={this.nav.bind(this, 'history')} className={['history', this.props.app.state.nav === 'history' && 'active'].join(' ')}></li>
            <li onClick={this.nav.bind(this, 'settings')} className={['settings', this.props.app.state.nav === 'settings' && 'active'].join(' ')}></li>
        </ul>);
    }
}