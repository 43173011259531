import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import '../Styles/navigation.css';
export class NavigationSelect extends React.Component {
    open(){
        this.props.app.setState({ header_opened_menu: !this.props.app.state.header_opened_menu });
    }
    menu(item, index){
        let info = this.props.app.state.cartInfo;
        info.pos = index;
        info.id = item.id;
        info.name = item.name;
        this.props.app.setState({cartInfo: info, header_opened_menu: false});
    }
    render() {
        let list = [];
        if(this.props.app.state.header_opened_menu){
            list = this.props.list.map((item, index)=>{
                let key = 'itemlist-' + index;
                return <li key={key} onClick={this.menu.bind(this, item, index)}>{item.name}</li>
            });
        }
        let tl = this.props.title.length;
        console.log('tl', tl, this.props.title);
        let cl = '';
        if(tl < 18){
            cl += 's1';
        } else if(tl < 26) {
            cl += 's2';
        } else {
            cl += 's3';
        }
        return (<div className={cl}  onClick={this.open.bind(this)}>
            {this.props.title} <span className="trd"></span>
            {this.props.app.state.header_opened_menu && [<div key='ns-0' onClick={this.open.bind(this)} className='title-help-bg'></div>,<ul key='ns-1' className='title-help'>
                {list}
            </ul>]}
        </div>);
    }
}