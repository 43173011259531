import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import packageJson from '../../package.json';
export class Loader extends React.Component {
    constructor(props) {
        super(props);
        //localStorage.removeItem('auth_hash');
        if (localStorage.getItem('auth_hash')) {
            let auth_hash = localStorage.getItem('auth_hash');
            this.props.app.getStartData(auth_hash);
        } else {
            //localStorage.setItem('auth_hash', 'a668e376-9686-408d-abb5-aaaafe498c70');
            this.props.app.goScreen('login');
        }
    }
    render() {
        return (<div className='Loader'>
            <span>Калькулятор<br/> Закупок</span>
            <div className="ver">
                ver: {packageJson.version} : {packageJson.hash}
            </div>
        </div>);
    }
}